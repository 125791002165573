var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "animated fadeIn" },
    [
      _c(
        "b-card",
        { attrs: { "body-class": "p-0" } },
        [
          _c(
            "b-card-header",
            [
              _c(
                "b-row",
                [
                  _c("b-col", { attrs: { cols: "8" } }, [
                    _c("h4", { staticClass: "mt-2" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("payments.titles.transactions")) +
                          " "
                      ),
                    ]),
                  ]),
                  _c(
                    "b-col",
                    { staticClass: "text-right mt-1", attrs: { cols: "4" } },
                    [
                      _vm.$rules.transaction.create
                        ? _c(
                            "b-button",
                            {
                              attrs: { variant: "success" },
                              on: {
                                click: function ($event) {
                                  return _vm.goTo(null)
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("payments.buttons.newCharge")) +
                                  " "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-card-body",
            { staticClass: "p-0 wallet-table-format" },
            [
              _c("custom-data-table", {
                ref: "wallet_table",
                attrs: {
                  fields: _vm.fields,
                  filters: _vm.filters,
                  query: _vm.drivers_transactions,
                  "fixed-filters": _vm.fixedFilters,
                  sort: _vm.sort,
                  "query-node": "transactions",
                },
                on: {
                  "vuetable:row-clicked": _vm.goTo,
                  "filter-change": function ($event) {
                    return _vm.onChangeFilter($event)
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "kind",
                    fn: function (props) {
                      return [
                        _c(
                          "big-badge",
                          {
                            staticClass: "mt-2",
                            attrs: {
                              variant: _vm.setKindFormat(props.rowData.kind),
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.setKindLabel(props.rowData.kind)) +
                                " "
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                  {
                    key: "description",
                    fn: function (props) {
                      return [
                        _c("div", { staticClass: "mb-1" }, [
                          _c("strong", [
                            _vm._v(_vm._s(props.rowData.description)),
                          ]),
                        ]),
                        _c("span", { staticClass: "text-muted" }, [
                          props.rowData.payment_method.type === "credit_card"
                            ? _c("span", [
                                _c("i", {
                                  class: _vm.setTypeIcon(
                                    props.rowData.payment_method.type
                                  ),
                                  staticStyle: {
                                    width: "18px",
                                    "text-align": "center",
                                  },
                                }),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("payments.enumMethod.creditCard")
                                    ) +
                                    " " +
                                    _vm._s(
                                      _vm.setCreditCardName(
                                        props.rowData.payment_method.cc_brand
                                      )
                                    ) +
                                    " (****" +
                                    _vm._s(
                                      props.rowData.payment_method.cc_number
                                    ) +
                                    ") "
                                ),
                              ])
                            : _vm._e(),
                          _c(
                            "p",
                            { staticClass: "pb-0 mb-0 pt-1" },
                            [
                              _c(
                                "b-link",
                                {
                                  attrs: {
                                    href: _vm.kovi_fatura + props.rowData.id,
                                    target: "_blank",
                                    title: "Abrir página de fatura",
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "fa fa-external-link",
                                    staticStyle: { "text-align": "center" },
                                  }),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("payments.texts.viewBill")
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]
                    },
                  },
                  {
                    key: "payment_method",
                    fn: function (props) {
                      return [
                        props.rowData.payment_method.type === "credit_card"
                          ? _c("i", {
                              staticClass: "payment-method__icon",
                              class: _vm.setTypeIcon(
                                props.rowData.payment_method.type
                              ),
                            })
                          : _vm._e(),
                        props.rowData.payment_method.type ===
                        "multi_payment_method"
                          ? _c("i", {
                              staticClass: "payment-method__icon",
                              class: _vm.setTypeIcon(
                                props.rowData.payment_method.type
                              ),
                            })
                          : _vm._e(),
                        props.rowData.payment_method.type === "pix"
                          ? _c("div", { staticClass: "payment-method__icon" }, [
                              _c("img", {
                                staticClass: "payment-img__center",
                                staticStyle: { width: "35px" },
                                attrs: {
                                  src: require("@assets/logos/pix.png"),
                                },
                              }),
                              _c("span", { staticClass: "h6" }, [
                                _vm._v("PIX"),
                              ]),
                            ])
                          : _vm._e(),
                        props.rowData.payment_method.type === "oxxo"
                          ? _c("div", { staticClass: "payment-method__icon" }, [
                              _c("img", {
                                staticStyle: {
                                  width: "35px",
                                  display: "inline-block",
                                },
                                attrs: {
                                  src: require("@assets/logos/oxxo.svg"),
                                },
                              }),
                            ])
                          : _vm._e(),
                        props.rowData.payment_method.type === "boleto"
                          ? _c("div", { staticClass: "payment-method__icon" }, [
                              _c("img", {
                                staticStyle: {
                                  width: "35px",
                                  display: "inline-block",
                                },
                                attrs: {
                                  src: require("@assets/logos/billet.svg"),
                                },
                              }),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                  {
                    key: "overdue_limit",
                    fn: function (props) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.setOverdueLimit(
                                props.rowData.overdue_limit,
                                props.rowData.timezone
                              )
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                  {
                    key: "amount",
                    fn: function (props) {
                      return [
                        _c(
                          "span",
                          {
                            class:
                              props.rowData.discount === 0 &&
                              props.rowData.status !== "NESTED_NEGOTIATED"
                                ? "d-block"
                                : "strike-through d-block",
                            style:
                              "color:" + _vm.setAmountColor(props.rowData.type),
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.setPriceFormat(
                                    _vm.$t("payments.labels.currency"),
                                    props.rowData.amount_reference
                                  )
                                ) +
                                " "
                            ),
                          ]
                        ),
                        props.rowData.discount > 0
                          ? _c(
                              "span",
                              {
                                staticClass: "d-block",
                                style: "color:" + _vm.setAmountColor(""),
                                attrs: { title: "Desconto Aplicado" },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.setPriceFormat(
                                        _vm.$t("payments.labels.currency"),
                                        _vm.reissueAmount(props.rowData)
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            )
                          : _vm._e(),
                        props.rowData.discount > 0
                          ? _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "b-tooltip",
                                    rawName: "v-b-tooltip.hover",
                                    modifiers: { hover: true },
                                  },
                                ],
                                staticClass: "badge badge-secondary",
                                attrs: {
                                  title: `${_vm.$t(
                                    "payments.labels.discountInTransaction"
                                  )} ${_vm.setPriceFormat(
                                    _vm.$t("payments.labels.currency"),
                                    props.rowData.discount
                                  )}`,
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "fa fa-info-circle ml-1",
                                }),
                                _vm._v(" Infos "),
                              ]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                  {
                    key: "status",
                    fn: function (props) {
                      return [
                        _c(
                          "big-badge",
                          {
                            attrs: {
                              variant: _vm.setStatusFormat(props.rowData),
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("transaction_status")(
                                    props.rowData.status
                                  )
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                  {
                    key: "contestation",
                    fn: function (props) {
                      return [
                        props.rowData.contestation
                          ? _c("badge-contestation", {
                              attrs: {
                                status: props.rowData.contestation.status,
                              },
                            })
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("modal-show-transaction", {
        attrs: { modal_data: _vm.modal_data },
        on: {
          refresh: _vm.onModalDataRefresh,
          updateAmountPaid: _vm.updateAmountPaid,
        },
        model: {
          value: _vm.showTransactionModal,
          callback: function ($$v) {
            _vm.showTransactionModal = $$v
          },
          expression: "showTransactionModal",
        },
      }),
      _c("modal-new-transaction", {
        attrs: { modal_data: _vm.modal_data },
        on: { refresh: _vm.onModalDataRefresh },
        model: {
          value: _vm.newTransactionModal,
          callback: function ($$v) {
            _vm.newTransactionModal = $$v
          },
          expression: "newTransactionModal",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }