<template>
  <div class="animated fadeIn">
    <b-card body-class="p-0">
      <b-card-header>
        <b-row>
          <b-col cols="8">
            <h4 class="mt-2">
              {{ $t('payments.titles.transactions') }}
            </h4>
          </b-col>
          <b-col cols="4" class="text-right mt-1">
            <b-button
              v-if="$rules.transaction.create"
              variant="success"
              @click="goTo(null)"
            >
              {{ $t('payments.buttons.newCharge') }}
            </b-button>
          </b-col>
        </b-row>
      </b-card-header>
      <b-card-body class="p-0 wallet-table-format">
        <custom-data-table
          ref="wallet_table"
          :fields="fields"
          :filters="filters"
          :query="drivers_transactions"
          :fixed-filters="fixedFilters"
          :sort="sort"
          query-node="transactions"
          @vuetable:row-clicked="goTo"
          @filter-change="onChangeFilter($event)"
        >
          <template slot="kind" slot-scope="props">
            <big-badge
              :variant="setKindFormat(props.rowData.kind)"
              class="mt-2"
            >
              {{ setKindLabel(props.rowData.kind) }}
            </big-badge>
          </template>
          <template slot="description" slot-scope="props">
            <div class="mb-1">
              <strong>{{ props.rowData.description }}</strong>
            </div>
            <span class="text-muted">
              <span v-if="props.rowData.payment_method.type === 'credit_card'">
                <i :class="setTypeIcon(props.rowData.payment_method.type)" style="width: 18px; text-align: center" />
                {{ $t('payments.enumMethod.creditCard') }} {{ setCreditCardName(props.rowData.payment_method.cc_brand) }} (****{{
                  props.rowData.payment_method.cc_number
                }})
              </span>
              <p class="pb-0 mb-0 pt-1">
                <b-link :href="kovi_fatura + props.rowData.id" target="_blank" title="Abrir página de fatura">
                  <i class="fa fa-external-link" style="text-align: center;" />
                  {{ $t('payments.texts.viewBill') }}
                </b-link>
              </p>
            </span>
          </template>

          <template slot="payment_method" slot-scope="props">
            <i
              v-if="props.rowData.payment_method.type === 'credit_card'"
              class="payment-method__icon"
              :class="setTypeIcon(props.rowData.payment_method.type)"
            />
            <i
              v-if="props.rowData.payment_method.type === 'multi_payment_method'"
              class="payment-method__icon"
              :class="setTypeIcon(props.rowData.payment_method.type)"
            />
            <div
              v-if="props.rowData.payment_method.type === 'pix'"
              class="payment-method__icon"
            >
              <img
                style="width: 35px;"
                class="payment-img__center"
                src="@assets/logos/pix.png"
              >
              <span class="h6">PIX</span>
            </div>
            <div
              v-if="props.rowData.payment_method.type === 'oxxo'"
              class="payment-method__icon"
            >
              <img
                style="width: 35px; display: inline-block;"
                src="@assets/logos/oxxo.svg"
              >
            </div>
            <div
              v-if="props.rowData.payment_method.type === 'boleto'"
              class="payment-method__icon"
            >
              <img
                style="width: 35px; display: inline-block;"
                src="@assets/logos/billet.svg"
              >
            </div>
          </template>

          <template slot="overdue_limit" slot-scope="props">
            {{ setOverdueLimit(props.rowData.overdue_limit, props.rowData.timezone) }}
          </template>

          <template slot="amount" slot-scope="props">
            <span
              :style="'color:' + setAmountColor(props.rowData.type)"
              :class="(props.rowData.discount === 0 && props.rowData.status !== 'NESTED_NEGOTIATED') ? 'd-block':'strike-through d-block'"
            >
              {{ setPriceFormat($t('payments.labels.currency'), props.rowData.amount_reference) }}
            </span>

            <span
              v-if="props.rowData.discount > 0"
              :style="'color:' + setAmountColor('')"
              title="Desconto Aplicado"
              class="d-block"
            >
              {{ setPriceFormat($t('payments.labels.currency'), reissueAmount(props.rowData)) }}
            </span>

            <span
              v-if="props.rowData.discount > 0"
              v-b-tooltip.hover
              class="badge badge-secondary"
              :title="`${ $t('payments.labels.discountInTransaction') } ${setPriceFormat($t('payments.labels.currency'), props.rowData.discount)}`"
            >
              <i class="fa fa-info-circle ml-1" />
              Infos
            </span>
          </template>

          <template slot="status" slot-scope="props">
            <big-badge :variant="setStatusFormat(props.rowData)">
              {{ props.rowData.status | transaction_status }}
            </big-badge>
          </template>
          <template slot="contestation" slot-scope="props">
            <badge-contestation
              v-if="props.rowData.contestation"
              :status="props.rowData.contestation.status"
            />
          </template>
        </custom-data-table>
      </b-card-body>
    </b-card>
    <modal-show-transaction v-model="showTransactionModal" :modal_data="modal_data" @refresh="onModalDataRefresh"
                            @updateAmountPaid="updateAmountPaid"
    />
    <modal-new-transaction v-model="newTransactionModal" :modal_data="modal_data" @refresh="onModalDataRefresh" />
  </div>
</template>

<style>
.wallet-table-format .custom-data-table .card {
  border: none;
  border-top: 1px solid #c8ced3;
  margin-bottom: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.wallet-table-format .custom-data-table .custom-data-table__filters {
  margin: 20px 20px 10px 20px !important;
}

.payment-method__icon {
  width: 100%;
  text-align: center;
  font-size: 18pt;
  color: #666666;
}

.payment-img__center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 32px;
}

.strike-through { text-decoration: line-through; }
.payment-negotiated { opacity: 0.5; }
</style>

<script>
import { formatPrice } from '@utils/numbers';
import { formatStatus, formatKind, parseKind } from '@utils/wallet';
import ModalShowTransaction from '@components/modals/show-transaction';
import ModalNewTransaction from '@components/modals/new-transaction';
import BigBadge from '@components/shared/big-badge';
import { status as DRIVERSDOCUMENT_STATUS } from '@utils/drivers-document';
import { transactionKind, transactionStatus } from '@utils/transaction';
import CustomDataTable from '@components/CustomDataTable';
import DRIVER_TRANSACTIONS from '@graphql/transaction/queries/driverWallet.gql';
import { isBrazil } from '@utils/helper-region';
import DRIVER_GET_BALANCE from '@graphql/driver/queries/get-balance.gql';

export default {
  name: 'DriverTransactions',
  page: {
    title: 'Carteira - Motoristas',
  },
  components: {
    CustomDataTable,
    ModalShowTransaction,
    ModalNewTransaction,
    BigBadge,
    BadgeContestation: () => import('@components/shared/badge-contestation')
  },
  data() {
    return {
      showTransactionModal: false,
      newTransactionModal: false,
      modal_data: {},
      kovi_fatura_br: process.env.KOVI_FATURA_URL,
      kovi_fatura_mx: process.env.KOVI_FATURA_URL_MX,
      fields: [
        {
          name: 'kind',
          title: this.$t('payments.tables.kind'),
          sortField: 'kind',
        },
        {
          name: 'description',
          title: this.$t('payments.tables.description'),
          sortField: 'description',
        },
        {
          name: 'payment_method',
          title: this.$t('payments.tables.payment_method'),
          class: 'w-100 d-block text-center',
          sortField: 'payment_method',
        },
        {
          name: 'overdue_limit',
          title: this.$t('payments.tables.overdue_limit'),
          sortField: 'overdue_limit',
        },
        {
          name: 'amount',
          title: this.$t('payments.tables.amount'),
          sortField: 'amount',
        },
        {
          name: 'status',
          title: this.$t('payments.tables.status'),
          class: 'text-center',
          sortField: 'status',
        },
        {
          name: 'contestation',
          title: this.$t('payments.tables.contestation'),
          class: 'text-center',
          sortField: 'status',
        },
      ],
      sort: [['overdue_limit', 'DESC'], ['created_at', 'DESC']],
      drivers_transactions: DRIVER_TRANSACTIONS,
      balance: 0,
      kindAllValues: transactionKind.map(kind => kind.value),
    };
  },
  computed: {
    filters() {
      return {
        kind: {
          type: 'select',
          props: {
            label: this.$t('payments.labels.tableFilterType'),
            options: [
              {
                value: this.kindAllValues,
                text: this.$t('payments.labels.tableFilterDefaultValue'),
              },
              ...transactionKind,
            ],
          },
          valueParserType: String,
          graphqlQuery(value) {
            return {
              kind: value,
            };
          },
        },
        overdue_limit: {
          type: 'datepicker',
          props: {
            label: this.$t('payments.labels.tableFilterExpireDate'),
            range: false,
            format: 'DD/MM/YYYY',
            confirm: false,
            width: 'auto',
          },
          valueParserType: String,
          valueParserFunction: date => {
            const userTimezone = this.$store.getters['user/entityRegion']?.country === 'MX'
                                    ? 'America/Mexico_City'
                                    : 'America/Sao_Paulo';
            const driverTimezone = this.$store.getters['driver/header']?.timezone;
            return this.$moment.tz(date, driverTimezone || userTimezone);
          },
          graphqlQuery(date) {
            if (date.isValid())
              return {
                overdue_limit: {
                  $and: {
                    $gte: date.clone().startOf('day').utc().format('YYYY-MM-DD HH:mm:ss'),
                    $lte: date.clone().endOf('day').utc().format('YYYY-MM-DD HH:mm:ss'),
                  },
                },
              };
          },
        },
        status: {
          type: 'select',
          props: {
            label: this.$t('payments.labels.tableFilterStatus'),
            options: [
              {
                value: transactionStatus.map(status => status.value),
                text: this.$t('payments.labels.tableFilterDefaultValue'),
              },
              ...transactionStatus,
            ],
          },
          valueParserType: String,
          graphqlQuery(value) {
            if (value === 'MANUALLY_PAID')
              return {
                manual_confirmation: true,
              };

            return {
              status: value,
            };
          },
        }
      };
    },
    fixedFilters() {
      return {
        driver: this.$route.params.id,
        ...{ kind: this.kindAllValues }
      };
    },
    kovi_fatura() {
      if (isBrazil()) {
        return this.kovi_fatura_br;
      } else {
        return this.kovi_fatura_mx;
      }
    }
  },
  mounted () {
    this.getWalletBalance();
  },
  methods: {
    calcDiscount (applied_discounts) {
      const invalidDiscountTypes = [ "payment", "pre_payment", "credit", "wallet_adjust_exchange"];
      const validDiscounts = applied_discounts.filter(item => !invalidDiscountTypes.includes(item.type));
      const totalDiscount = validDiscounts.reduce((sum, item) => sum+item.amount, 0 );

      return totalDiscount;
    },
    walletAdjustDiscount(walletAdjustments) {
      const outType = 'BALANCE_DEBITED'
      const walletDiscounts = walletAdjustments.filter(item => item.type === outType);
      const totalDiscount = walletDiscounts.reduce((sum, item) => sum+(item.metadata.amount ?? 0), 0 );

      return totalDiscount;
    },
    reissueAmount(data){
      const taxes = data.invoice_object?.taxes?.total || 0
      return (data.amount_reference + taxes) - data.discount
    },
    updateAmountPaid(args) {
      const { id, amount_paid } = args
      const tableData = this.$refs.wallet_table.tableData
      const transactionIndex = tableData.findIndex(transaction => transaction.id === id);
      tableData[transactionIndex] = { ...tableData[transactionIndex], amount_paid }
    },
    setStatusFormat(transaction) {
      let status = transaction.status;

      if (!transaction) return;
      if (!transaction.status) return 'STATUS_ERROR';
      return formatStatus(status);
    },
    setKindFormat: formatKind,
    setKindLabel: parseKind,
    setAmountColor(_type) {
      return _type === 'CREDIT' ? '#4dbd74' : '#f86c6b';
    },
    setTypeIcon(_type) {
      switch(_type) {
        case 'credit_card':
          return 'fa fa-credit-card-alt';
        case 'boleto':
          return 'fa fa-barcode';
        case 'multi_payment_method':
          return 'fa fa-money'
        default:
          return ''
      }
    },
    setCreditCardName(_name) {
      return _name.charAt(0).toUpperCase() + _name.slice(1);
    },
    setPriceFormat(_currency, _value) {
      return formatPrice(_currency, _value);
    },
    setOverdueLimit(_overdue_limit, _timezone) {
      const utc = this.$moment.utc(_overdue_limit);
      return this.$moment.tz(utc, _timezone).format("DD/MM/YYYY");
    },
    setModalStateIfItemNull() {
      this.modal_data.id = null;
      this.modal_data.driver = this.$route.params.id;
      this.modal_data.booking = 'manual-transaction';
      this.modal_data.payment_method = null;
      this.modal_data.payment_method_type = null;
      this.modal_data.payment_method_token = null;
      this.modal_data.payment_method_cc_brand = null
      this.modal_data.payment_method_cc_number = null
      this.modal_data.type = 'DEBIT';
      this.modal_data.kind = null;
      this.modal_data.amount = null;
      this.modal_data.amount_paid = null;
      this.modal_data.amount_reference = null;
      this.modal_data.discount = null;
      this.modal_data.description = null;
      this.modal_data.status = null;
      this.modal_data.internal_comments = null;
      this.modal_data.overdue_limit = null;
      this.modal_data.created_at = null;
      this.modal_data.updated_at = null;
      this.modal_data.proof = null;
      this.modal_data.balance = 0;
      this.modal_data.manual_confirmation_at = null;
      this.modal_data.timezone = null
      this.modal_data.gateway = null
      this.modal_data.holmes_checked_at = null
      this.modal_data.holmes_status = null
      this.modal_data.applied_discounts = 0;
      this.modal_data.method_available = [];

    },
    setModalStateIfItemExist({ data: _item }) {
      this.modal_data.id = _item?.id;
      this.modal_data.driver = this.$route.params.id;
      this.modal_data.transaction_operations = _item?.transaction_operations;
      this.modal_data.invoice = _item?.invoice;
      this.modal_data.total_available = _item?.invoice_object?.total_available;
      this.modal_data.method_available = _item?.invoice_object?.method_available;

      if (_item?.booking) {
        const booking_started_at = this.$moment(_item?.booking?.started_at).format('DD/MM/YYYY');
        const booking_finished_at = this.$moment(_item?.booking?.finished_at).format('DD/MM/YYYY') || null;
        if (_item?.booking?.finished_at) {
          this.modal_data.booking_name = `${_item?.booking?.plan?.name} - ${booking_started_at} a ${booking_finished_at}`;
        } else {
          this.modal_data.booking_name = `${_item?.booking?.plan?.name} - Ativo`;
        }

        this.modal_data.booking = _item?.booking.id;
        this.modal_data.booking_started_at = booking_started_at;
        this.modal_data.booking_finished_at = booking_finished_at;
        this.modal_data.car = _item?.booking.car?.id || ''
        this.modal_data.car_plate = _item?.booking.car?.license_plate || ''
      } else {
        this.modal_data.booking = 'manual-transaction';
        this.modal_data.booking_name = 'Pagamento avulso (Sem aluguel)';
        this.modal_data.car = ''
        this.modal_data.car_plate = ''
      }
      this.modal_data.installment = _item?.installment;
      this.modal_data.payment_method = _item?.payment_method?.id;
      this.modal_data.payment_method_type = _item?.payment_method?.type;
      this.modal_data.payment_method_type_updated = _item.invoice_object?.payment_method || _item?.payment_method?.type;
      this.modal_data.payment_method_token = _item?.payment_method?.token;
      this.modal_data.payment_method_cc_brand = _item?.payment_method?.cc_brand
      this.modal_data.payment_method_cc_number = _item?.payment_method?.cc_number
      this.modal_data.barcode_number = _item?.barcode_number;
      this.modal_data.type = _item?.type;
      this.modal_data.kind = _item?.kind;
      this.modal_data.interest = _item?.invoice_object?.taxes?.interest;
      this.modal_data.fine = _item?.invoice_object?.taxes?.fine;
      this.modal_data.days_late = _item?.invoice_object?.taxes?.days_late;
      this.modal_data.transaction_fee = _item?.invoice_object?.taxes?.transaction_fee;
      this.modal_data.taxes_total = _item?.invoice_object?.taxes?.total;
      this.modal_data.amount = _item?.invoice_object?.amount
      this.modal_data.amount_paid = _item?.amount_paid;
      this.modal_data.amount_reference = _item?.invoice_object?.amount_reference;
      this.modal_data.discount = _item?.invoice_object?.discount;
      this.modal_data.applied_discounts = this.calcDiscount(_item?.invoice_object?.applied_discounts);
      this.modal_data.wallet_adjustment_discount = this.walletAdjustDiscount(_item?.walletAdjustments);
      this.modal_data.description = _item?.description;
      this.modal_data.status = _item?.status;
      this.modal_data.internal_comments = _item?.internal_comments;
      this.modal_data.overdue_limit = _item?.overdue_limit;
      this.modal_data.created_at = _item?.created_at;
      this.modal_data.updated_at = _item?.updated_at;
      this.modal_data.paid_at = _item?.paid_at;
      this.modal_data.proof = _item?.proof;
      this.modal_data.balance = this.balance
      this.modal_data.manual_confirmation_at = _item?.manual_confirmation_at ? _item?.manual_confirmation_at : null
      this.modal_data.timezone = _item?.timezone
      this.modal_data.gateway = _item?.gateway
      this.modal_data.holmes_checked_at = _item?.holmes_checked_at
      this.modal_data.holmes_status = _item?.holmes_status
      this.modal_data.contestation = _item?.contestation
    },
    goTo(_item = null) {
      if (_item === null) {
        this.setModalStateIfItemNull();
        this.newTransactionModal = true;
      } else {
        this.setModalStateIfItemExist(_item);
        this.showTransactionModal = true;
      }
    },
    async getWalletBalance () {
      try {
        const response = await this.$apollo.query({
          query: DRIVER_GET_BALANCE,
          variables: {
            driver: this.$route.params.id
          },
        });
        this.balance = response.data.getBalance.balance_amount
      } catch (err) {
        this.$log.logError(err);
      }
    },
    onModalDataRefresh() {
      this.$refs.wallet_table.$_refresh();
      this.getWalletBalance();
      this.showTransactionModal = false;
      this.newTransactionModal = false;
      this.$emit('updateBalance')
    },
    onChangeFilter(event) {
      if (event) {
        if (event.filterName === 'kind') {
          this.fixedFilters.kind = event.event.kind
        }
      }
    }
   }
};
</script>
